import { Alert, Button, Form, Input } from 'antd';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import EmptyLayout from '../../components/layout/EmptyLayout';
import SearchEngineOptimization from '../../components/utility/seo';
import { useCustomer } from '../../hooks/use-customer';

interface formValue {
  email: string;
}

const ForgotPassword = () => {
  const [form] = Form.useForm<formValue>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    actions: { requestResetPassword },
  } = useCustomer();

  const onSubmit = async (formValue) => {
    try {
      setLoading(true);
      await requestResetPassword(formValue.email);
      navigate('/forgot-password/instruction-sent');
    } catch (_) {
      console.log('error', _);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmptyLayout title={'รีเซ็ตรหัสผ่าน'}>
      <SearchEngineOptimization title="Reset password" />
      <Form
        form={form}
        onFinish={onSubmit}
        disabled={loading}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="อีเมล"
          required={false}
          rules={[{ required: true, type: 'email', message: 'กรุณากรอกอีเมล' }]}
        >
          <Input />
        </Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" block>
          รีเซ็ตรหัสผ่าน
        </Button>
        {error && (
          <Alert
            className="!items-baseline !text-light-title !mt-4"
            message="ไม่สามารถรีเซ็ตรหัสผ่านได้ กรุณาลองใหม่อีกครั้ง หรือติดต่อฝ่าย IT ของเราหากคุณยังพบปัญหาอยู่"
            type="error"
            showIcon
          />
        )}
      </Form>
    </EmptyLayout>
  );
};

export default ForgotPassword;
